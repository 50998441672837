import React, { useCallback, useMemo, useRef } from 'react';
import { createReactEditorJS } from 'react-editor-js';
import { EDITOR_I18N, EDITOR_JS_TOOLS } from './tools';
import { ClickAwayListener } from '@mui/material';
import { isString } from 'lodash';

const ReactEditorJS = createReactEditorJS();

/**
 *
 * @param {{
 *  placeholder: string,
 *  value: any,
 *  onChange: () =>{},
 *  tools: {
 *  embed?: Boolean,
 *  table?: Boolean,
 *  list?: Boolean,
 *  warning?: Boolean,
 *  code?: Boolean,
 *  link?: Boolean,
 *  image?: Boolean,
 *  raw?: Boolean,
 *  header?: Boolean,
 *  quote?: Boolean,
 *  marker?: Boolean,
 *  checklist?: Boolean,
 *  delimiter?: Boolean,
 *  inlinecode?: Boolean,
 *  simpleImage?: Boolean
 * }
 *
 * }} props
 * @returns
 */
export default function CustomEditorjs({ placeholder = 'Saisir un message', value, onChange, tools }) {
  const editorRef = useRef(null);
  const editor_tools = useMemo(() => EDITOR_JS_TOOLS(tools), [tools]);

  const convertDefaultValue = useMemo(() => {
    if (isString(value))
      return {
        blocks: [
          {
            type: 'paragraph',
            data: { text: value }
          }
        ],
        version: '2.28.2'
      };

    return value;
  }, [value]);

  const handleInitialize = useCallback((instance) => {
    editorRef.current = instance;
  }, []);

  const handleSave = useCallback(async () => {
    const saveData = await editorRef.current?.save();

    if (onChange) onChange(saveData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClickAwayListener onClickAway={handleSave}>
      <div>
        <ReactEditorJS
          minHeight={180}
          i18n={EDITOR_I18N}
          tools={editor_tools}
          placeholder={placeholder}
          onInitialize={handleInitialize}
          {...(value && { defaultValue: convertDefaultValue })}
        />
      </div>
    </ClickAwayListener>
  );
}
