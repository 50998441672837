import { Dialog, DialogContent, IconButton, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { capitalize } from 'lodash';
import { Icon } from '@iconify/react';
import { Close } from '@mui/icons-material';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMailContext } from 'src/section/mail/MailContext';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import Iconify from 'src/components/Iconify';
import useToggle from 'src/hooks/useToggle';

export default function MailV2CustomLogin({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{
          width: 400
        }}
      >
        <Stack width={1} pb={1} direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'h6'}>Lier un compte mail</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <CustomMailForm onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
}

export const CustomMailForm = ({ onClose }) => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { loginCustomMail } = useMailContext();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('email non valide').required('Ce champs est requis '),
    password: Yup.string().required('Ce champs est requis '),

    smtp_host: Yup.string().required('Ce champs est requis '),
    smtp_port: Yup.number().required('Ce champs est requis '),

    imap_host: Yup.string().required('Ce champs est requis '),
    imap_port: Yup.number().required('Ce champs est requis ')
  });

  const initialValues = {
    email: '',
    password: '',

    smtp_host: '',
    smtp_port: undefined,

    imap_host: '',
    imap_port: undefined
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      const data = {
        userId: user?.id,
        user: {
          email: values?.email,
          password: values?.password,
          displayName: user?.displayName
        },
        smtp: {
          host: values?.smtp_host,
          port: values?.smtp_port
        },
        imap: {
          host: values?.imap_host,
          port: values?.imap_port
        }
      };

      loginCustomMail(data, onClose, (message) => {
        formikHelpers.setSubmitting(false);
        enqueueSnackbar(message, { variant: 'error' });
      });

      //console.log(data);
    }
  });

  const { getFieldProps, touched, errors, handleSubmit, isSubmitting } = formik;

  const getField = (name) => {
    return {
      ...getFieldProps(name),
      error: Boolean(touched[name] && errors[name]),
      helperText: touched[name] && errors[name]
    };
  };

  return (
    <Stack spacing={2}>
      <FormField label={'Email'} icon={'arcticons:spike-email'} {...getField('email')} />

      <FormField label={'Mot de passe'} icon={'arcticons:password'} {...getField('password')} isPassword />

      <ServerForm label={'Serveur SMTP'} hostProps={getField('smtp_host')} portProps={getField('smtp_port')} />

      <ServerForm label={'Serveur IMAP'} hostProps={getField('imap_host')} portProps={getField('imap_port')} />

      <LoadingButton
        onClick={handleSubmit}
        loading={isSubmitting}
        variant={'contained'}
        sx={{
          backgroundColor: 'primary.main'
        }}
      >
        Se connecter
      </LoadingButton>
    </Stack>
  );
};

const ServerForm = ({ label, hostProps, portProps }) => {
  return (
    <Stack width={1} spacing={1}>
      <Typography pl={0.5} fontSize={14}>
        {capitalize(label)}
      </Typography>
      <Stack width={1} direction={'row'} spacing={1}>
        <Stack width={'70%'}>
          <TextField label={'Hôte'} placeholder={'hôte'} size={'small'} {...hostProps} />
        </Stack>
        <Stack spacing={1} width={'30%'}>
          <TextField label={'Port'} placeholder={'port'} size={'small'} type={'number'} {...portProps} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const FormField = ({
  label,
  helperText,
  error,
  anchor,
  InputProps,
  icon,
  isPassword,
  endDecodation = null,
  ...rest
}) => {
  const iconSize = { height: 20, width: 20 };
  const { open, handleSwitch } = useToggle();

  return (
    <Stack spacing={1}>
      <Typography pl={0.5} fontSize={14}>
        {capitalize(label)}
      </Typography>
      <TextField
        label={' '}
        size={'small'}
        type={isPassword && !open ? 'password' : 'text'}
        {...rest}
        InputProps={{
          startAdornment: (
            <IconButton size="small" sx={{ mr: 2 }}>
              <Iconify icon={icon} {...iconSize} />
            </IconButton>
          ),
          ...(isPassword && {
            endAdornment: (
              <IconButton size="small" onClick={handleSwitch}>
                <Iconify icon={open ? 'ic:outline-visibility' : 'ic:outline-visibility-off'} {...iconSize} />
              </IconButton>
            )
          })
        }}
      />
      {helperText && (
        <Typography variant={'caption'} color={error ? 'red' : 'black'} fontSize={12}>
          {helperText}
        </Typography>
      )}
    </Stack>
  );
};
