import PropTypes from 'prop-types';
import { useState, useRef, useEffect, useMemo, memo } from 'react';
// @mui
import {
  Stack,
  Avatar,
  TextField,
  Typography,
  ListItemText,
  ListItemAvatar,
  InputAdornment,
  Button,
  ListItem,
  IconButton,
  Checkbox
} from '@mui/material';

import { Button as ButtonJoy } from '@mui/joy';

// ...
// _mock_

// components
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import createAvatar, { getAvatarUrl } from 'src/utils/createAvatar';
import { toSimpleAccessUser } from 'src/helpers/user';
import MenuPopover from 'src/components/MenuPopover';
import { themeColor } from 'src/constants/color';
import { Icon } from '@iconify/react';
import { useToggleV2 } from 'src/hooks/useToggle';
import { useSelector } from 'src/redux/store';

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------



/**
 *
 * @param {{
 * assigne: Array,
 * onAssigne: function(Array),
 * action: (popoverRef:any, onOpen:function)? {},
 * title: string?,
 * toBlock: Array?,
 * isSimpleUserID: boolean,
 * CustomList: Array | null ,
 * onlyAssignee: boolean,
 * disabled: boolean,
 * singleSelect: boolean
 * }} props
 * @example <ContactsDialog
 *            assigne={[]}
 *            onAssigne={(users)=> }
 *            action ={(popoverRef, onOpen) => }
 *            titile=''
 *            toBlock={[]}
 *          />
 * @returns
 */
const ContactsDialog = ({
  assigne,
  onAssigne,
  action = null,
  task,
  isSimpleUserID = false,
  title = 'Assigné(s)',
  toBlock = [],
  CustomList = null,
  onlyAssignee = false,
  disabled = false,
  singleSelect = false
}) => {
  const popoverRef = useRef();
  const [filterName, setFilterName] = useState('');
  const [open, onOpen, onClose] = useToggleV2();
  const { users: _contacts } = useSelector((state) => state.user);
  const [selected, setSelected] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);

  const usersList = useMemo(() => {
    if (CustomList && CustomList?.length > 0) {
      return CustomList;
    }
    return _contacts;
  }, [CustomList, _contacts]);

  const converAssigne = useMemo(() => {
    if (isSimpleUserID) {
      return assigne.map((_id) => _contacts.find((one) => one?.id === _id));
    }
    return assigne;
  }, [assigne, _contacts, isSimpleUserID]);

  const [assignee, setAssigne] = useState(converAssigne);

  const handleSearchQuery = (event) => {
    setFilterName(event.target.value);
  };

  const handleSelect = (user) => {
    //si l'utilisateur est déjà selectionné, on le retire
    if (Boolean(singleSelect)) {
      setSelected([user]);
    } else {
      if (selected.find((_user) => _user.id === user.id)) {
        setSelected((prev) => prev.filter((_user) => _user.id !== user.id));
      } else {
        setSelected((prev) => [...prev, user]);
      }
    }
  };

  const handleSelectAll = () => {
    setSelected([...usersList]);
  };

  const handleUnselectAll = () => {
    setSelected([]);
  };

  const assigneAllSelected = () => {
    setAssigne((prev) => [...prev, ...selected]);
    setSelected([]);
  };

  const unAssignAllSelected = () => {
    setAssigne((prev) => prev.filter((_user) => !selected.find((_one) => _one.id === _user.id)));
    setSelected([]);
  };

  const mostSelectedIsAssigned = useMemo(() => {
    // si parmis les utlisateurs selectioner il y a plus de personne assigné que de personne non assigné
    return selected.filter((_user) => assignee.find((_one) => _one.id === _user.id)).length > selected.length / 2;
  }, [selected, assignee]);

  const handleAssigne = (user) => {
    const existIndex = assignee.findIndex((_one) => _one.id === user.id || _one === user.id);

    if (existIndex !== -1) {
      const updatedAssignee = [...assignee];
      updatedAssignee.splice(existIndex, 1);
      setAssigne(updatedAssignee);
    } else {
      setAssigne((prev) => [...prev, toSimpleAccessUser(user)]);
    }
  };

  const dataFiltered = useMemo(() => {
    if (onlyAssignee) {
      return converAssigne;
    }

    return applySortFilter({
      listData: [...(CustomList || _contacts)],
      filterName
    });
  }, [CustomList, _contacts, filterName, converAssigne, onlyAssignee]);

  const handleClose = () => {
    onClose();
    onAssigne(assignee);
  };

  useEffect(() => setAssigne(converAssigne), [converAssigne]);

  return (
    <>
      {!action && (
        <IconButton
          ref={popoverRef}
          size="small"
          onClick={onOpen}
          sx={{ p: 0.8, border: `1px solid ${themeColor.CARROT_ORANGE}`, color: themeColor.CARROT_ORANGE }}
          disabled={disabled}
        >
          <Icon icon="fluent:person-add-20-regular" />
        </IconButton>
      )}

      {action && action(popoverRef, onOpen)}

      <MenuPopover
        arrow="left-center"
        horizontal="right"
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        width={450}
        anchorEl={popoverRef.current}
        open={open && Boolean(popoverRef.current)}
        onClose={handleClose}
      >
        <Stack spacing={2} sx={{ p: 2.5, pb: 1 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">
              {title} <Typography component="span">({assignee?.length || 0})</Typography>
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button
                startIcon={
                  <Icon height={15} width={15} icon={!mostSelectedIsAssigned ? 'eva:plus-fill' : 'eva:minus-fill'} />
                }
                onClick={mostSelectedIsAssigned ? unAssignAllSelected : assigneAllSelected}
                color={mostSelectedIsAssigned ? 'error' : 'inherit'}
                sx={{ fontSize: 12 }}
                disabled={disabled || !selected.length}
                variant="soft"
              >
                {mostSelectedIsAssigned ? 'Retirer' : 'Assigner'}
              </Button>
              <Button size="small" onClick={handleClose} color="primary" variant="contained" disabled={disabled}>
                Terminer
              </Button>
            </Stack>
          </Stack>

          <TextField
            fullWidth
            value={filterName}
            onChange={handleSearchQuery}
            label="Rechercher"
            placeholder="Rechercher ..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              )
            }}
          />
          {selected.length >= 0 && (
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {selected.length} sélectionné(s)
              </Typography>
              <ButtonJoy
                size="small"
                onClick={usersList?.length === selected.length ? handleUnselectAll : handleSelectAll}
                color="neutral"
                variant="soft"
                sx={{ padding: '5px 5px' }}
              >
                {usersList?.length === selected.length ? 'Tout désélectionner' : 'Tout sélectionner'}
              </ButtonJoy>
            </Stack>
          )}
        </Stack>

        <Scrollbar
          sx={{
            height: ITEM_HEIGHT * 6,
            p: 1,
            '& .MuiMenuItem-root': {
              px: 1.5,
              height: ITEM_HEIGHT,
              borderRadius: 0.75
            }
          }}
        >
          {dataFiltered.map((contact, idx) => {
            const isAssigne = [...assignee].find((_user) => _user?.id === contact?.id);
            const canBlock = toBlock.find((_one) => _one === contact?.id);
            const isSelect = selected.find((_user) => _user.id === contact.id);

            return (
              <ListItem
                key={contact.id + idx}
                dense
                sx={{
                  borderRadius: 1,
                  bgcolor: isSelect ? 'action.selected' : 'background.paper',
                  ...(idx !== dataFiltered.length && { mb: 1 })
                }}
                secondaryAction={
                  !canBlock ? (
                    <Button
                      startIcon={<Icon height={15} width={15} icon={!isAssigne ? 'eva:plus-fill' : 'eva:minus-fill'} />}
                      onClick={() => handleAssigne(contact)}
                      color={isAssigne ? 'error' : 'inherit'}
                      sx={{ fontSize: 12 }}
                      disabled={disabled || isSelect}
                      variant="text"
                    >
                      {isAssigne ? 'Retirer' : 'Assigner'}
                    </Button>
                  ) : (
                    <Button color="error" sx={{ fontSize: 12 }}>
                      Bloqué
                    </Button>
                  )
                }
              >
                <Stack onMouseEnter={() => setHoveredItem(contact)} onMouseLeave={() => setHoveredItem(null)}>
                  <ListItemAvatar sx={{ position: 'relative' }}>
                    {hoveredItem?.id === contact.id || isSelect ? (
                      <Checkbox
                        checked={isSelect}
                        onChange={() => handleSelect(contact)}
                        color="primary"
                        disabled={disabled}
                      />
                    ) : (
                      <Avatar
                        src={getAvatarUrl(contact)}
                        sx={{
                          color: 'white',
                          bgcolor: contact?.avatar
                            ? 'default'
                            : createAvatar(contact?.displayName || contact?.name).color2
                        }}
                      >
                        {createAvatar(contact?.displayName || contact?.name).name}
                      </Avatar>
                    )}
                  </ListItemAvatar>
                </Stack>

                <ListItemText
                  primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                  secondaryTypographyProps={{ typography: 'caption' }}
                  primary={contact?.displayName || contact?.name}
                  secondary={contact.email}
                />
              </ListItem>
            );
          })}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

ContactsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default memo(ContactsDialog);

// ----------------------------------------------------------------------

function applySortFilter({ listData, filterName }) {
  if (filterName) {
    listData = listData.filter(
      (item) =>
        item.displayName?.toLowerCase()?.indexOf(filterName.toLowerCase()) !== -1 ||
        item?.email?.toLowerCase()?.indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return listData;
}
